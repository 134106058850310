import { Component, GlobalComponents } from "vue";
import Tools from "./Tools";
import Tron from "./Tron/Tron";

export default class Global {
    /**
     * Main页
     */
    public static $M: {
        /**
         * 选择组件
         * @param component_name 
         * @param component_props 传参
         */
        SelectComponent(component_name: string, component_props: any): void
    }



    /**
     * APP
     */
    public static app?: {
        $router: any,
        /**
         * 设置全局组件
         * @param component_name 
         * @param props 
         */
        SetGlobalComponent(component_name: keyof GlobalComponents, props: any): void,
        /**
         * 关闭Global组件
         */
        CloseGlobalComponent(): void;
        /**
         * 显示输入框
         * @param default_value 
         * @param default_placeholder 
         * @param callBackFun 
         */
        ShowInputBox(default_value: string, default_placeholder: string, callBackFun: (v: string) => void): void;
        /**
         * 关闭输入框
         */
        CloseInputBox(): void;
        ShowLoading(message: string): void;
        CloseLoading(): void;
    };

    /**
     * 最后的参数
     */
    public static params_data_dic: { [url: string]: any } = {};


    /**
     * 获取USDT货币价格
     * @returns 
     */
    public static async GetUSDTAmountAsync(): Promise<{
        usd: number,
        cny: number
    }> {
        // try {
        //     let response = await fetch("https://api.coingecko.com/api/v3/simple/price?ids=tether&vs_currencies=usd,cny");
        //     let v = await response.json();
        //     let d: any = {

        //     };
        //     for (let _key in v) {
        //         d = v[_key];
        //     }
        //     return d;
        // }
        // catch (e) {
        //     return {
        //         usd: 7.1358,
        //         cny: 1
        //     }
        // }
        return Promise.resolve({
            usd: 7.1358,
            cny: 1
        });
    }

    /**
     * 显示输入框
     * @param {string} default_value 
     * @param {string} default_placeholder 
     * @param {function(v:string)} callBackFun 
    */
    public static ShowInputBox(default_value: string, default_placeholder: string, callBackFun: (v: string) => void) {

        Global.app && Global.app?.ShowInputBox(default_value, default_placeholder, callBackFun);
    }

    /**
     * 关闭输入框
     */
    public static CloseInputBox(): void {
        Global.app && Global.app?.CloseInputBox();
    }


    public static ShowLoading(message: string) {
        Global.app && Global.app.ShowLoading(message);
    }

    public static CloseLoading() {
        Global.app && Global.app.CloseLoading();
    }
}