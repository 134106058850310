import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue")
  },
  {
    path: "/main",
    name: "Main",
    component: () => import("@/views/Main.vue")
  },
  {
    path: '/demo',
    name: 'Demo',
    component: () => import("@/views/Demo.vue")
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
