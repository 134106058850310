import router from "@/router";
import Global from "./Global";
import ToolsBase from "./ToolsBase";
let window = globalThis;
export default class Tools extends ToolsBase {
    /**
     * Post提交
     * @param action 
     * @param action_type 
     * @param data 
     * @returns 
     */
    public static async PostHTMLAsync(action: string, action_type: "wallet" | "bus" | "sys", data?: any[] | any, content_type: "application/x-www-form-urlencoded" |
        "multipart/form-data" |
        "application/xml" |
        "application/json" |
        "application/javascript" |
        "text/plain" |
        "text/html" = "application/x-www-form-urlencoded"): Promise<{
            code: number,
            data: any
        }> {
        return new Promise(r => {
            try {
                this.GetHTML(action, action_type, data, "POST", content_type, succ_res => {
                    r(succ_res)
                });
            }
            catch (e) {
                r({
                    code: 0,
                    data: `异常:${e}`
                });
            }
        });
    }

    /**
     * Get方式获取HTML
     * @param action 指令
     * @param action_type 类型 
     * @param data 数据
     * @returns 
     */
    public static async GetHTMLAsync(action: string, action_type: "wallet" | "bus" | "sys", data?: any[] | any): Promise<{
        code: number,
        data: any
    }> {
        //过滤空值
        // if (data instanceof Array) {
        //     for (let i = data.length - 1; i >= 0; i--) {
        //         const v = data[i];
        //         if (Tools.StrIsNull(v)) {
        //             data.splice(i, 1);
        //         }
        //     }
        // }

        return new Promise(r => {
            try {
                this.GetHTML(action, action_type, data, "GET", "application/x-www-form-urlencoded", succ_res => {
                    r(succ_res)
                });
            }
            catch (e) {
                r({
                    code: 0,
                    data: `异常:${e}`
                });
            }
        });
    }

    /**
     * 显示LOG
     * @param tag 标签
     * @param msg 日志内容
     * @param color 颜色
     * @param font_size 字体大小
    */
    static ShowLog(tag?: string, msg: any = "", color: string = "#0a0", font_size: number = 12): void {
        globalThis.console.log(`%c【${tag}】:${msg}`, `color:${color};font-size:${font_size}px`);
    }

    /**
     * 关闭全局组件
     */
    static CloseGlobalComponent() {
        Global.app && Global.app?.CloseGlobalComponent();
    }

    /**
     * 弹出失败消息
     * @param error_msg 
     * @param out_time 超时时间 默认3000毫秒
     */
    static AlertError(error_msg: string | undefined, out_time: number = 3000) {
        Global.app && Global.app.SetGlobalComponent("Alert", {
            type: "error",
            message: error_msg,
            out_time: out_time
        });
    }

    /**
     * 弹出成功消息
     * @param success_msg 成功消息提示
     * @param out_time 超时时间 
     */
    static AlertSuccess(success_msg: string, out_time: number = 3000): void {
        Global.app && Global.app.SetGlobalComponent("Alert", {
            type: "success",
            message: success_msg,
            out_time: out_time
        });
    }

    static StrIsNull(v: string): boolean {

        switch (typeof v) {
            case "number":
                debugger;
                v = (<number>v).toString();
                break;
            case "string":
                if (!v)
                    return true;
                break;
            case "object":
                if (!v)
                    return true;
            default:
                return true;
        }
        if (v == "")
            return true;
        return false;
    }

    static StrIsNotNull(v: string): boolean {
        return !this.StrIsNull(v);
    }


    /**
     * 跳转
     * @param href 字符串跳转 或 数字跳转 -1代表返回
     * @param query Get传参
     * @param params Post传参
     * @param to_replice 是否替换方式跳转
     */
    public static GoTo(href: string | number, query: any = {}, params: any = {}, to_replice: boolean = false): void {
        if (typeof href == "string") {
            let name: string = "";
            for (let i = 0; i < router.getRoutes().length; i++) {
                const v = router.getRoutes()[i];
                if (v.path == href) {
                    name = v.name?.toString() as string;
                }
            }

            Global.params_data_dic[href] = params;
            if (to_replice) {
                Global.app && Global.app.$router.replace({
                    path: href,
                    query: query,
                    name: name,
                    params: params
                });
            }
            else {
                Global.app && Global.app.$router.push({
                    path: href,
                    query: query,
                    name: name,
                    params: params
                });
            }
        }
        else if (typeof href == "number") {
            switch (href) {
                case -1:
                    Global.app && Global.app.$router.back();
                    break;
                default:
                    Global.app && Global.app.$router.go(href);
                    break;
            }
        }
    }

    /**
     * 设置存储
     * @param key 
     * @param v 
     */
    public static SetLocalStorage(key: string, v: string) {
        globalThis.localStorage.setItem(key, v);
    }

    /**
     * 获取本地存储
     * @param key 
     * @returns 
     */
    public static GetLocalStorage(key: string) {
        return globalThis.localStorage.getItem(key);
    }

    public static LocalStorageClear() {
        globalThis.localStorage.clear();
    }

    /**
     * 设置本地Session
     * @param key 
     * @param v 
     * @returns 
     */
    public static SetSessionStroage(key: string, v: string) {
        return globalThis.sessionStorage.setItem(key, v);
    }

    /**
     * 获取本地Session
     * @param key 
     * @returns 
     */
    public static GetSessionStroage(key: string): string {
        return globalThis.sessionStorage.getItem(key) as '';
    }

    /**
     * 
     */
    public static ClearSessionStroage() {
        globalThis.sessionStorage.clear();
    }


    /**
     * 获取当前系统时间
     * @param {number} timeZone 时区 默认东八区
     * @returns {Date} 
     */
    static GetNowTime(timeZone: number = 8): Date {
        //获取本地时间与格林威治时间的时间差(注意是分钟，记得转换)
        const diff = new Date().getTimezoneOffset();
        //根据本地时间和时间差获得格林威治时间
        const absTime = new Date().getTime() + diff * 60 * 1000;
        //根据格林威治时间和各地时区，得到各地时区的时间
        let localTime = new Date(absTime + timeZone * 60 * 60 * 1000);
        return localTime;
    }

    /**
    * 获取系统时间
    * @param {Date} date 
    * @returns {{            
            year: number,
            month: number,
            date: number,
            hour: number,
            min: number,
            sec: number
      }}
     */
    static GetDateTime(date: Date) {

        if (typeof date == "string") {
            date = new Date(date);
        }
        let _year = date.getFullYear();
        var _month = date.getMonth() + 1;//月
        var _date = date.getDate();//日
        var _hour = date.getHours();//时
        var _min = date.getMinutes();//分
        var _sec = date.getSeconds();//秒
        return {
            year: _year,
            month: _month,
            date: _date,
            hour: _hour,
            min: _min,
            sec: _sec
        }
    }

    /**
     * 获取时间
     * @param {Date} d 
     * @returns {String}
     */
    static GetDateYYYYMMddHHmmss(d: Date): string {
        let v = this.GetDateTime(d);
        return `${v.year}-${this.GetTime(v.month)}-${this.GetTime(v.date)} ${this.GetTime(v.hour)}:${this.GetTime(v.min)}:${this.GetTime(v.sec)}`;
    }

    static GetTime(v: string | number) {
        v = +v;
        if (v <= 9) {
            return `0${v}`;
        }
        return v;
    }


    /**
     * 时间转换成时间戳
     * @param {string | number | object} v 
     * @returns {number}
     */
    static TimeToLong(v: number | any | string) {
        if (typeof v == "number")
            return v;
        if (typeof v == "object")
            return new Date(v).getTime();
        if (Tools.StrIsNull(v))
            return 0;
        v = v.replace(/-/g, "/")
        let time = new Date(v);
        return time.getTime();
    }

    /**
     * 获取时间
     * @param {Date} d 
     * @returns {String}
     */
    static GetDateYYYYMMdd(d: Date) {
        let v = this.GetDateTime(d);
        return `${v.year}-${v.month}-${v.date}`;
    }

    /**
     * 获取时间戳(精确到秒)
     * @param {string} d 
     * @returns 
     */
    static GetDateToIntTime(d: string) {
        let timestamp = Date.parse(d);
        return timestamp / 1000;
    }

    /**
     * 获取时间戳(精确到毫秒)
     * @param {string} d 
     * @returns 
     */
    static GetDateToLongTime(d: string) {
        let timestamp = Date.parse(d);
        return timestamp;
    }

}