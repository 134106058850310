<template>
    <div class="LoadingBody">
        <div class="content">
            <span>{{ message }}</span>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        message: String
    },
    data() {
        return {

        }
    },
    methods: {},
    created() {

    }
}
</script>
<style lang="scss" scoped>
.LoadingBody {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1000;
    display:flex;
    align-items: center;
    justify-content: center;
    .content {
        
    }
}
</style>