<template>
  <div :class="['alert_body', type == 'success' ? 'success_body' : 'error_body']">
    <div class="content">
      <div>{{ message }}</div>
    </div>
  </div>
</template>
<script>
import SuperTimer from '@/script/SuperTimer'
import Tools from '@/script/Tools';
export default {
  data() {
    return {}
  },
  props: {
    message: String,
    type: String,
    out_time: Number
  },
  created() {
    SuperTimer.Start("Alert", () => {
      Tools.CloseGlobalComponent();
      return false;
    }, this.out_time);
  }
}
</script>
<style lang="scss" scoped>
.success_body {
  background-color: #42b983;
}

.error_body {
  background-color: #ff5722;
}

.alert_body {
  width: 100%;
  padding: 5px;
  font-weight: bold;
  text-align: center;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 99999;
  .content {
    font-size: 12px;
  }
}
</style>