import Config from "./Config";
import Tools from "./Tools";
let window = globalThis;
/**
 * 基础工具包
 */
export default class ToolsBase {

    /**
     * 检查是否正确
     * @param v 
     * @returns 
     */
    public static CheckHTMLIsOk(v: {
        code: number,
        data: any
    }, show_error: boolean = true) {
        if (v.code == 1) {
            return true;
        }
        else {
            if (show_error)
                if (typeof v.data == "string")
                    v.data.AlertError();
        }
        return false;
    }
    /**
     * 
     * @param action 
     * @param action_type 
     * @param data 
     * @param method 
     * @param content_type 
     * @param succ 
     * @param fail 
     */
    protected static GetHTML(
        action: string,
        action_type: "wallet" | "bus" | "sys",
        data: any[] | any,
        method: "GET" | "POST",
        content_type: "application/x-www-form-urlencoded" |
            "multipart/form-data" |
            "application/xml" |
            "application/json" |
            "application/javascript" |
            "text/plain" |
            "text/html",
        succ: (res: {
            code: number,
            data: any
        }) => void) {
        try {
            let _body: any = null;
            let url = "";
            switch (action_type) {
                case "bus":
                    url = Config.API_Business_URL;
                    break;
                case "wallet":
                    url = Config.API_Wallet_URL;
                    break;
                case "sys":
                    url = Config.API_SYS_URL;
                    break;
                default:
                    throw "类型异常!";
            }
            url = `${url}${action}/`;

            if (method == "GET") {
                if (data) {
                    if ('length' in data) {
                        data.forEach((v: string) => {
                            if (Tools.StrIsNotNull(v))
                                url += `${v}/`;
                        });
                    }
                    else {
                        for (const key in data) {
                            if (Object.prototype.hasOwnProperty.call(data, key)) {
                                const v = data[key];
                                if (Tools.StrIsNotNull(v)) {
                                    if (url.indexOf("?") == -1) {
                                        url += `?${key}=${encodeURI(v)}`;
                                    }
                                    else {
                                        url += `&${key}=${encodeURI(v)}`;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            else if (method == "POST") {
                if (data) {
                    if ('length' in data) {
                        //如果是数组
                        data.forEach((v: string) => {
                            if (Tools.StrIsNotNull(v))
                                url += `${v}/`;
                        });
                    }
                    else {
                        switch (content_type) {
                            case "multipart/form-data":
                                const formData = new FormData();
                                for (const key in data) {
                                    if (Object.prototype.hasOwnProperty.call(data, key)) {
                                        const v = data[key];
                                        if (Tools.StrIsNotNull(v))
                                            formData.append(key, v);
                                    }
                                }
                                _body = formData;
                                break;
                            case "application/x-www-form-urlencoded":
                                let post_url = "";
                                for (const key in data) {
                                    if (Object.prototype.hasOwnProperty.call(data, key)) {
                                        const v = data[key];
                                        if (Tools.StrIsNotNull(v))
                                            post_url += `${key}=${encodeURI(v)}&`;
                                    }
                                }
                                if (post_url.length > 0) {
                                    post_url = post_url.substring(0, post_url.length - 1);
                                }
                                _body = post_url;
                                break;
                            case "application/javascript":
                                _body = data;
                                break;
                            case "application/json":
                                _body = JSON.stringify(data);
                                break;
                        }
                    }
                }
            }

            globalThis.fetch(url, {
                method: method,
                body: method == "GET" ? null : _body,
                headers: {
                    'content-type': content_type
                }
            }).then(response => {
                return response.json();
            }).then(data => {

                succ && succ(data);
            }).catch(err => {
                succ && succ(err);
            });
        }
        catch (e) {
            succ && succ({
                code: 0,
                data: `异常:${e}`
            });
        }
    }
}