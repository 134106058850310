import { ref } from "vue";
import { ContractAbi } from "web3";

export default class Config {
    private static IS_DEBUG: boolean = false;
    /**
     * API地址
     */
    private static API_URL: string = this.IS_DEBUG ? "http://127.0.0.1:4000/api/" : "https://api.chainbank.lookbao.net/api/";
    // private static API_URL: string = "https://api.chainbank.lookbao.net/api/"; 
    /**
     * 钱包相关转账地址
     */
    public static API_Wallet_URL: string = `${Config.API_URL}wallet/`;

    /**
     * 业务功能地址
     */
    public static API_Business_URL: string = `${Config.API_URL}bus/`;

    /**
     * 系统级地址
     */
    public static API_SYS_URL: string = `${Config.API_URL}sys/`;

    public static TronConfig: {
        fullNodeURL: string,
        solidityNodeURL: string,
        eventServerURL: string,
        UsdtContractAddress: string,
        SysContractAddress: string
    } = {
            //#region 区块链相关
            fullNodeURL: "https://tron.fullnode.lookbao.net",
            solidityNodeURL: "https://tron.fullnode.lookbao.net",
            eventServerURL: "http://tron.fullnode.lookbao.net:16669/",
            /**
             * USDT合约地址
             */
            UsdtContractAddress: "TGJ8Jr8w2inxsKob9wjxXsUgvyeDFtoviA",
            /**
             * 系统合约地址
             */
            SysContractAddress: "TFWcFiAmbqHs82AQF994BJGfZpRY16Qr3b"
            //#endregion
        };

    public static ETHConfig: {
        HOST: string,
        SysContractAddress: string,
        UsdtContractAddress: string,
        /**
         * 收款地址
         */
        ReceivingAddress: string,
        ERC20ABI: ContractAbi
    } = {
            HOST: this.IS_DEBUG ? "http://localhost:9001" : "https://empty-smart-model.discover.quiknode.pro/20de4e0e80cb1402886e795e8bfb6fbfa561d446/",
            SysContractAddress: this.IS_DEBUG ? '0x3a5242c309a41a52340f2003e742ea97d060a5c8' : "0xAf374f156BfA435b545a6999da14Ea27B9a8a173",
            UsdtContractAddress: this.IS_DEBUG ? '0xa14a69cdc924d73c1064640614b2b895ee8de17d' : "0xdAC17F958D2ee523a2206206994597C13D831ec7",
            ReceivingAddress: this.IS_DEBUG ? "0xB495419A6fdD0FeB99c4333Ab2BF0054E6Bcc7E6" : "0xF0ce1E0B350090f57332036073137A1C652f9141",
            ERC20ABI: [
                {
                    "anonymous": false,
                    "inputs": [
                        {
                            "indexed": true,
                            "internalType": "address",
                            "name": "_owner",
                            "type": "address"
                        },
                        {
                            "indexed": true,
                            "internalType": "address",
                            "name": "_spender",
                            "type": "address"
                        },
                        {
                            "indexed": false,
                            "internalType": "uint256",
                            "name": "_value",
                            "type": "uint256"
                        }
                    ],
                    "name": "Approval",
                    "type": "event"
                },
                {
                    "anonymous": false,
                    "inputs": [
                        {
                            "indexed": true,
                            "internalType": "address",
                            "name": "_from",
                            "type": "address"
                        },
                        {
                            "indexed": true,
                            "internalType": "address",
                            "name": "_to",
                            "type": "address"
                        },
                        {
                            "indexed": false,
                            "internalType": "uint256",
                            "name": "_value",
                            "type": "uint256"
                        }
                    ],
                    "name": "Transfer",
                    "type": "event"
                },
                {
                    "inputs": [],
                    "name": "name",
                    "outputs": [
                        {
                            "internalType": "string",
                            "name": "name",
                            "type": "string"
                        }
                    ],
                    "stateMutability": "nonpayable",
                    "type": "function"
                },
                {
                    "inputs": [],
                    "name": "symbol",
                    "outputs": [
                        {
                            "internalType": "string",
                            "name": "symbol",
                            "type": "string"
                        }
                    ],
                    "stateMutability": "nonpayable",
                    "type": "function"
                },
                {
                    "inputs": [],
                    "name": "decimals",
                    "outputs": [
                        {
                            "internalType": "uint8",
                            "name": "decimals",
                            "type": "uint8"
                        }
                    ],
                    "stateMutability": "nonpayable",
                    "type": "function"
                },
                {
                    "inputs": [],
                    "name": "totalSupply",
                    "outputs": [
                        {
                            "internalType": "uint256",
                            "name": "totalSupply",
                            "type": "uint256"
                        }
                    ],
                    "stateMutability": "nonpayable",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "address",
                            "name": "_owner",
                            "type": "address"
                        }
                    ],
                    "name": "balanceOf",
                    "outputs": [
                        {
                            "internalType": "uint256",
                            "name": "balance",
                            "type": "uint256"
                        }
                    ],
                    "stateMutability": "nonpayable",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "address",
                            "name": "_to",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "_value",
                            "type": "uint256"
                        }
                    ],
                    "name": "transfer",
                    "outputs": [
                        {
                            "internalType": "bool",
                            "name": "success",
                            "type": "bool"
                        }
                    ],
                    "stateMutability": "nonpayable",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "address",
                            "name": "_from",
                            "type": "address"
                        },
                        {
                            "internalType": "address",
                            "name": "_to",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "_value",
                            "type": "uint256"
                        }
                    ],
                    "name": "transferFrom",
                    "outputs": [
                        {
                            "internalType": "bool",
                            "name": "success",
                            "type": "bool"
                        }
                    ],
                    "stateMutability": "nonpayable",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "address",
                            "name": "_spender",
                            "type": "address"
                        },
                        {
                            "internalType": "uint256",
                            "name": "_value",
                            "type": "uint256"
                        }
                    ],
                    "name": "approve",
                    "outputs": [
                        {
                            "internalType": "bool",
                            "name": "success",
                            "type": "bool"
                        }
                    ],
                    "stateMutability": "nonpayable",
                    "type": "function"
                },
                {
                    "inputs": [
                        {
                            "internalType": "address",
                            "name": "_owner",
                            "type": "address"
                        },
                        {
                            "internalType": "address",
                            "name": "_spender",
                            "type": "address"
                        }
                    ],
                    "name": "allowance",
                    "outputs": [
                        {
                            "internalType": "uint256",
                            "name": "remaining",
                            "type": "uint256"
                        }
                    ],
                    "stateMutability": "nonpayable",
                    "type": "function"
                }
            ] as const
        };
}