import Web3, { Contract, ContractAbi } from "web3";
import Config from "../Config";
import Tools from "../Tools";
import ERC20 from "./Erc20";
import EthContract from "./EthContract";
export type ContractKey = "SYS" | "USDT";
export default class MetaMask {
    public static is_init:boolean = false;
    private static web3: Web3;
    private static _select_address: string = "";
    private static wallet_address_list: string[] = [];

    /**
     * 合约字典
     */
    private static contract_dic: { [key in ContractKey]?: ERC20 } = {};

    /**
     * 获取当前选择的区块链地址
     */
    public static get SelectAddress(): string {
        if (this.wallet_address_list.length == 0)
            Tools.GoTo("/");
        return this._select_address;
    }
    public static set SelectAddress(address: string) {
        this._select_address = address;
    }

    public static SetWalletAddressList(address_list: string[]) {
        this.wallet_address_list = address_list;
    }

    public static get ethereum() {
        return window?.ethereum ?? null;
    }

    /**
     * 检查是否是小狐狸
     */
    public static get isMetaMask() {
        let injectedProvider = false;
        if (typeof window.ethereum !== 'undefined') {
            injectedProvider = true
            console.log(window.ethereum)
        }
        return injectedProvider ? window?.ethereum?.isMetaMask : false;
    }


    /**
     * 连接到钱包并返回钱包地址
     * @returns 
     */
    public static async ConnectMetaMaskAsync(): Promise<string[]> {
        this.web3 = new Web3(this.ethereum!);
        await this.ethereum?.enable();
        let address = await this.ethereum?.request({
            method: "eth_requestAccounts",
        });

        //初始化监听器
        this.ethereum!.on("accountsChanged", (address_list) => {
            this.SetWalletAddressList(address_list as string[]);
            Tools.GoTo("/");
        });

        this.ethereum!.on("chainChanged", (chainId) => {
            Tools.GoTo("/");
            window.location.reload(); //直接刷新
        });
        return address as string[];
    }

    /**
     * 初始化合约
     */
    public static InitContract() {
        let c = this.CreateContract(Config.ETHConfig.SysContractAddress, Config.ETHConfig.ERC20ABI);
        this.contract_dic.SYS = new ERC20(c);

        c = this.CreateContract(Config.ETHConfig.UsdtContractAddress, Config.ETHConfig.ERC20ABI);
        this.contract_dic.USDT = new ERC20(c);
        this.is_init=true;
    }

    public static get Contract() {
        return this.contract_dic;
    }

    /**
     * 获取合约
     * @param contractAddress 
     * @param abi 
     */
    private static CreateContract(contractAddress: string, abi: ContractAbi) {
        const contract = new this.web3.eth.Contract(abi, contractAddress);
        return contract;
    }
}