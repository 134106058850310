import { createApp, reactive, ref } from 'vue'
import App from './App.vue'
import router from './router'
import Tron from './script/Tron/Tron';
import { InstallGlobalComponents } from "@/script/GlobalComponent";
import "@/script/GlobalExt";
import TronGlobal from './script/Tron/Global/TronGlobal';
import Config from './script/Config';
import Tools from './script/Tools';
import Global from './script/Global';
import MetaMask from './script/Eth/MetaMask';
//初始化连接
let app = createApp(App);


//#region 定义全局变量
let $G: { [key: string]: any } = app.config.globalProperties.$G = reactive({});

SetGlobalData("SystemConfig", async () => {
    let res = await Tools.GetHTMLAsync("GetSystemConfig", "sys");
    if (Tools.CheckHTMLIsOk(res)) {
        return res.data;
    }
    return {};
}, {});

SetGlobalData('USDT_BUY_LIST', async () => {
    let res = await Tools.GetHTMLAsync("GetPledgeUSDTConfigArr", "sys");
    if (Tools.CheckHTMLIsOk(res)) {
        return res.data;
    }
    return [];
}, []);


SetGlobalData('USDT_AMOUNT', async () => {
    let res = await Global.GetUSDTAmountAsync();
    return res;
}, {
    usd: 0,
    cny: 0
});


// SetGlobalData('USDT_Decimals', async () => {
//     let contract = await TronGlobal.Tron.contract.GetContractAsync(Config.TronConfig.UsdtContractAddress);
//     return await contract['decimals']().call();
// }, 0);


// SetGlobalData('SYS_Decimals', async () => {
//     let contract = await TronGlobal.Tron.contract.GetContractAsync(Config.TronConfig.SysContractAddress);
//     return await contract['decimals']().call();
// }, 0);

//#endregion

InstallGlobalComponents(app); //初始化全局组件

app.use(router).mount('#app')

/**
 * 设置全局变量
 * @param keyName 新增的键
 * @param _default 默认值
 * @param CallFunAsync 后期处理函数
 */
function SetGlobalData<T>(keyName: string, CallFunAsync: () => Promise<any>, _default: T) {
    CallFunAsync().then((r: any) => {
        app.config.globalProperties.$G[keyName] = r;
    });
    app.config.globalProperties.$G[keyName] = _default;
}
