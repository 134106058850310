<template>
  <input-component v-if="input_component_obj.show_input_box" :value="input_component_obj.value"
    :placeholder="input_component_obj.placeholder" :callBackFun="input_component_obj.callBackFun">
  </input-component>
  <LoadingComponent :message="loading_message" v-if="show_loading"></LoadingComponent>
  <component :is="GlobalComponent" v-bind="GlobalComponentProps"></component>
  <router-view />
</template>
<script>
import InputComponent from './components/InputComponent.vue';
import MetaMask from './script/Eth/MetaMask';
import Global from "./script/Global";
import Tools from './script/Tools';
import LoadingComponent from "@/components/LoadingComponent.vue";
export default {
  components: { InputComponent, LoadingComponent },
  setup() {
    // provide("ERC20_NAME", Config.ERC20_NAME);
  },
  data() {
    return {
      show_loading: false,
      loading_message: "",

      GlobalComponent: "",
      GlobalComponentProps: {},

      //#region 显示输入框
      input_component_obj: {
        show_input_box: false,
        value: "",
        placeholder: "",
        callBackFun: null
      }
      //#endregion

    };
  },
  methods: {
    ShowLoading(message) {
      this.loading_message = message;
      this.show_loading = true;
    },
    CloseLoading() {
      this.loading_message = "";
      this.show_loading = false;
    },
    /**
     * 显示输入框
     * @param {string} default_value 
     * @param {string} default_placeholder 
     * @param {function(v:string)} callBackFun 
     */
    ShowInputBox(default_value, default_placeholder, callBackFun) {

      this.input_component_obj.value = default_value;
      this.input_component_obj.placeholder = default_placeholder;
      this.input_component_obj.callBackFun = callBackFun;
      this.input_component_obj.show_input_box = true;
    },
    /**
     * 关闭输入框
     */
    CloseInputBox() {
      this.input_component_obj.show_input_box = false;
    },
    /**
     * 设置全局组件
     * @param {String} component_name
     * @param {any} props
     */
    SetGlobalComponent(component_name, props) {
      this.GlobalComponent = component_name;
      this.GlobalComponentProps = props;
    },
    /**
     * 关闭组件
     */
    CloseGlobalComponent() {
      this.GlobalComponent = "";
    },
  },
  created() {
    Global.app = this;
    if (!MetaMask.is_init)
      Tools.GoTo("/");
  },
  mounted() { },
};
</script>

<style lang="scss">
html,
body {
  width: 100%;
  height: 100%;
  // background-color: rgb(31, 31, 31);
  background-color: rgb(254, 196, 210);
  font-size: 12px;
  display: flex;
  color: white;
  overflow: hidden;
  display: flex;
  padding: 0px;
  margin: 0px;
}

#app {
  width: 100%;
  height: 100%;
}

/* 灰色 */
.to_gray {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
}
</style>

<style>
.success_btn {
  background-color: #42b983;
  color: white;
  border: 0px;
  border-radius: 0px;
}

.delete_btn {
  background-color: #ff5722;
  color: white;
  border: 0px;
  border-radius: 0px;
}

.error_btn {
  background-color: #ff5722;
  color: white;
  border: 0px;
  border-radius: 0px;
}

.warning_btn {
  background-color: #ffb800;
  color: white;
  border: 0px;
  border-radius: 0px;
}

/* input {
  height: 30px !important;
  font-size: 14px;
  font-weight: bold;
  background-color: rgb(51, 51, 51);
  border: 0px;
  text-align: center;
  color: white;
  border-radius: 6px;
}

button {
  border-radius: 6px !important;
  height: 30px;
}


select {
  background-color: rgb(51, 51, 51);
  color: white;
  border: 0px;
  font-size: 12px;
  text-align: center;
  height: 30px;
  border-radius: 6px;
} */


.row {
  display: flex;
}

.lie {
  display: flex;
  flex-direction: column;
}
</style>