<template>
    <div class="input_body">
        <div class="input_content">
            <span class="close_btn" @click="C">X</span>
            <span class="title">{{ v_placeholder }}</span>
            <input v-model="v_value" :placeholder="v_placeholder" />
            <div class="button_box">
                <button class="error_btn" @click="C">取消</button>
                <button class="success_btn" @click="S">确定</button>
            </div>
        </div>
    </div>
</template>
<script>
import Tools from '@/script/Tools';
export default {
    data() {
        return {
            v_value: "",
            v_placeholder: ""
        };
    },
    props: {
        placeholder: String,
        value: String,
        callBackFun: function () { }
    },
    methods: {
        C() {
            this.$parent.CloseInputBox();
        },
        S() {
            if(Tools.StrIsNull(this.v_value))
            {
                "值不能为空!".AlertError();
                return;
            }
            this.callBackFun && this.callBackFun(this.v_value);
            this.$parent.CloseInputBox();
        }
    },
    created() {
        
        this.v_value = this.value;
        this.v_placeholder = this.placeholder;
    },
    mounted() {
        
    }
}
</script>
<style lang="scss" scoped>
.input_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 5000;

    .input_content {
        display: flex;
        flex-direction: column;
        background-color: rgb(31, 31, 31);
        padding: 20px;
        width: 95%;
        box-sizing: border-box;
        border-radius: 10px;
        position: relative;

        .close_btn {
            position: absolute;
            top: 10px;
            right: 10px;
            color: white;
            border-radius: 50%;
            background-color: red;
            font-size: 18px;
            font-weight: bold;
            border: 0px;
            width: 25px;
            height: 25px;
            display: inline-block;
            text-align: center;
            line-height: 25px;
            box-sizing:border-box;
        }

        .title {
            font-size: 20px;
            font-weight: bold;
            display: block;
            text-align: center;
            margin-bottom: 10px;
        }

        .button_box {
            display: flex;
            width: 100%;
            justify-content: space-between;
            margin-top: 10px;

            button {
                width: 45%;
            }
        }
    }
}
</style>